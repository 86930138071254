<template>
  <div v-if="grade_ranges.length > 0">
    <template v-if="moreThanOneGradeRange && grade_range_group_id == null">
      <div class="th-table-ranges p-1">
        <b-form-group
          label-cols-sm="2"
          label="Rango Seleccionado:"
          label-for="input-range_group"
          class="mt-2 mr-2 ml-2"
        >
          <b-form-select
            id="input-range_group"
            :options="grandeGroupsList"
            v-model="selected_range_group"
            value-field="id"
            text-field="name"
            class=""
          >
          </b-form-select>
        </b-form-group>
      </div>
    </template>
    <b-table-simple
      v-if="gradeRangeList.length > 0"
      bordered
      hover
      class="table-ranges"
    >
      <b-thead>
        <b-tr>
          <b-th class="th-table-ranges" style="width: 40%">
            RANGOS DE NOTAS</b-th
          >
          <b-th class="th-table-ranges"> COLORES</b-th>
          <!-- <b-th class="th-table-ranges"> CANTIDAD</b-th>
          <b-th class="th-table-ranges"> PORCENTAJE</b-th> -->
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="range in gradeRangeList" :key="range.id">
          <b-td class="td-range-tables">{{ range.name.toUpperCase() }} </b-td>
          <b-td
            :style="{
              'background-color': range.color + ' !important',
            }"
          >
            <div>
              <strong>
                {{ range.start_grade }}
                <span v-if="range.include_start_grade">&le;</span>
                <span v-else>&lt;</span>
                x
                <span v-if="range.include_finish_grade">&le;</span>
                <span v-else>&lt;</span>
                {{ range.finish_grade }}
              </strong>
            </div>
          </b-td>
          <!-- <b-td>{{ countGrade(range).length }}</b-td>
          <b-td>
            <template v-if="total_grades.length > 0">
              {{
                ((countGrade(range).length / total_grades.length) * 100)
                  | Round
              }}%
            </template>
            <template v-else>0 %</template>
          </b-td> -->
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>

<script>
// import * as names from "@/store/names";
// import { mapGetters } from "vuex";
export default {
  name: "GradeTableRanges",
  components: {},
  props: {
    grade_ranges: {
      type: Array,
    },
    grade_ranges_groups: {
      type: Array,
    },
    grade_range_group_id: {
      type: [Number, null],
      default: null,
    },
    // Estructura de la props para que funcione y se pueda reutilizar.
    // [ 1.9, 6, 2.25, 1, 3.45, 1 ]
    // total_grades: {
    //   type: Array,
    // },
  },
  data() {
    return {
      selected_range_group: this.grade_range_group_id,
    };
  },
  computed: {
    // ...mapGetters({}),
    grandeGroupsIds() {
      return [...new Set(this.grade_ranges.map((x) => x.grade_range_group))];
    },
    grandeGroupsList() {
      return this.grade_ranges_groups.filter((x) =>
        this.grandeGroupsIds.includes(x.id)
      );
    },
    moreThanOneGradeRange() {
      if (this.grade_range_group_id != null) return false;
      else {
        if (this.grandeGroupsIds.length > 1) return true;
        else return false;
      }
    },
    gradeRangeList() {
      if (this.grade_range_group_id != null) {
        return this.grade_ranges
          .filter((x) => x.grade_range_group == this.grade_range_group_id)
          .sort(function (a, b) {
            if (a.finish_grade < b.finish_grade) return 1;
            if (a.finish_grade > b.finish_grade) return -1;
            if (a.start_grade < b.start_grade) return 1;
            if (a.start_grade > b.start_grade) return -1;
            return 0;
          });
      } else {
        return this.grade_ranges
          .filter((x) => x.grade_range_group == this.selected_range_group)
          .sort(function (a, b) {
            if (a.finish_grade < b.finish_grade) return 1;
            if (a.finish_grade > b.finish_grade) return -1;
            if (a.start_grade < b.start_grade) return 1;
            if (a.start_grade > b.start_grade) return -1;
            return 0;
          });
      }
    },
  },
  methods: {
    // countGrade(rango) {
    //   return this.total_grades.filter((grade) => {
    //     let incluirNota = false;
    //     const {
    //       start_grade,
    //       finish_grade,
    //       include_start_grade,
    //       include_finish_grade,
    //     } = rango;
    //     if (include_start_grade && include_finish_grade) {
    //       incluirNota = start_grade <= grade && grade <= finish_grade;
    //     } else if (include_start_grade) {
    //       incluirNota = start_grade <= grade && grade < finish_grade;
    //     } else if (include_finish_grade) {
    //       incluirNota = start_grade < grade && grade <= finish_grade;
    //     } else {
    //       incluirNota = start_grade < grade && grade < finish_grade;
    //     }
    //     return incluirNota;
    //   });
    // },
  },
  mounted() {
    if (this.grade_range_group_id == null) {
      this.selected_range_group = this.grandeGroupsIds[0];
    }
  },
  watch: {
    selected_range_group(value) {
      if (value != null) this.$emit("selected_range_group", value);
    },
  },
  created() {},
};
</script>

<style scoped>
.report-ranges-caption {
  width: 100%;
  border: 1px solid #dee2e6;
  font-size: var(--secondary-font-size);
}
.table-ranges {
  font-size: var(--secondary-font-size);
}
.th-table-ranges {
  background: var(--kl-menu-color) !important;
  color: white;
  font-weight: bold;
}
.td-range-tables {
  font-weight: bold;
}
.report-ranges-header {
  text-align: left;
  padding: 0.5em 1em;
  padding-left: 2em;
  background: var(--kl-menu-color) !important;
}
.report-range {
  display: flex;
  margin-left: 0;
  padding: 0.5em 4em;
  border-bottom: 1px solid #dee2e6;
}
.range-label {
  font-weight: bold;
  text-align: left;
  width: 25%;
}
.report-range .range-value-red {
  background: #f19292;
  width: 25%;
}
.report-range .range-value-yellow {
  background: #fff1ac;
  width: 25%;
}
.report-range .range-value-green {
  background: #bbeaa6;
  width: 25%;
}
</style>